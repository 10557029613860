<template>
    <Popup title="Login">
        <form>
            <FormGroup
                    v-model:parent_ref="formData.username"
                    label="Username"
                    autocomplete="username, email"
            />
            <FormGroup
                    v-model:parent_ref="formData.password"
                    label="Password"
                    type="password"
                    autocomplete="current-password"
            />
            <div class="form-group submit-group">
                <div class="other-buttons">
                    <button
                            class="other-button"
                            type="button"
                            @click.prevent="openRegisterPopup"
                    >
                        Register
                        <img alt="Register" src="~/assets/icons/right_arrow.svg">
                    </button>
                    <button
                            class="other-button"
                            type="button"
                            @click.prevent="openForgotPasswordPopup"
                    >
                        Forgot Password
                        <img alt="Register" src="~/assets/icons/right_arrow.svg">
                    </button>
                </div>
                <button type="submit" @click.prevent="login">
                    Login
                    <img alt="Login" src="~/assets/icons/right_arrow.svg" width="14" height="14">
                </button>
            </div>
        </form>
    </Popup>
</template>

<script setup>
import {usePopup} from "~/composables/usePopup";
import {notify} from "@kyvg/vue3-notification";

// login

const formData = reactive({
    username: "",
    password: "",
});

const loading = ref(false);

const {query} = useRoute();
const redirect = query.redirect;

const login = async () => {
    if (loading.value) return;
    loading.value = true;

    try {
        const response = await $fetch("/api/user/login", {
            method: "POST",
            body: {
                username: formData.username,
                password: formData.password,
            },
            ignoreResponseError: true,
        });

        if (response.status) {
            notify({
                type: "success",
                title: "You have successfully logged in"
            })

            const {setPopup} = usePopup();
            setPopup(null);

            const {refreshUser} = useUser();
            await refreshUser(response.session);

            if (redirect) {
                window.location.href = redirect;
            }
        } else if (response.error) {
            notify({
                type: "error",
                title: response.error
            })
        } else {
            notify({
                type: "error",
                title: "Error occurred while logging in"
            })
        }
    } catch (e) {
        notify({
            type: "error",
            title: "Something went wrong"
        })
    } finally {
        loading.value = false;
    }
};

const {popup, setPopup} = usePopup();

const openForgotPasswordPopup = () => {
    const ForgotPasswordPopup = defineAsyncComponent(() => import("~/components/popups/ForgotPasswordPopup.vue"));
    setPopup(ForgotPasswordPopup);
};

const openRegisterPopup = async () => {
    const RegisterPopup = defineAsyncComponent(() => import("~/components/popups/RegisterPopup.vue"));
    if (popup.value !== RegisterPopup) {
        setPopup(RegisterPopup);
    }
};
</script>